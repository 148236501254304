import Vue from 'vue';
import { getCheckCode, register } from '../../api/registerApi';



export default {
  components: {},
  data() {
    return {

    };
  },
  methods: {
    back(){
      this.$router.push({name:'myOrder'})
    },
    toHome(){
      this.$router.push({name:'Home'})
    },
    toOrder(){
      this.$router.push({name:'myOrder'})
    }

  },
};
